
import React, { useEffect } from 'react';
import { Root, Routes } from 'react-static';
import {
  createHistory,
  LocationProvider,
  createMemorySource,
  Link,
} from '@reach/router';
import ReactGA from 'react-ga';

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import BackgroundStyle from './components/BackgroundStyle';
import Warning from './components/Warning';
import Footer from './components/Footer';

import './app.scss';

ReactGA.initialize('UA-21784898-5');
let history;
if (typeof window !== 'undefined') {
  history = createHistory(window);
  console.log(history);
  const WebFont = require('webfontloader');
  WebFont.load({
    google: {
      families: ['Bitter', 'Lobster', 'Nunito'],
    },
  });

  ReactGA.pageview(window.location.pathname);
  history.listen((window) => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log('page=>', window.location.pathname);
  });
} else {
  history = createHistory(createMemorySource('/'));
}

function App() {


  if (typeof window !== 'undefined') {
    useEffect(() => {
      CookieConsent.run({
        guiOptions: {
          consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
          },
        },
        categories: {
          necessary: {
            readOnly: true
          },
          analytics: {}
        },
        language: {
          default: "en",
          autoDetect: "browser",
          translations: {
            en: {
              consentModal: {
                title: "Hello traveler, it's cookie time!",
                description: "We use cookies to ensure you get the best experience on our website. We also use them to measure site performance and to serve you the best possible content.",
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: "Reject all",
              },
            }
          }
        }
      });
    });
  }

  return (
    <Root>
      <Warning fixed />
      <BackgroundStyle />
      <LocationProvider history={history}>
        <Routes />
      </LocationProvider>
      <Footer />
    </Root>
  );
}

export default App;
