import React from 'react';

import './style.scss';

const BackgroundStyle = () => (
  <div id="grad-container">
    <div id="back-grad-2" />
    <div id="back-grad" />
    <div id="rear-grad" />
    <div id="front-grad" />
  </div>
);

export default BackgroundStyle;
