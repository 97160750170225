import React from 'react';
import jsonp from 'jsonp';
import axios from 'axios';
import './style.scss';

const url =
  'https://fireislandferries.us9.list-manage.com/subscribe/post-json?u=bd6005a37150dc3c40eab5bee&id=294ba5eba0';

class MailingList extends React.Component {
  state = {
    status: null,
    message: null,
  };

  subscribe = async (email) => {
    console.log(email);
    const subscribeUrl = `${url}&b_email=${email}`;
    this.setState({
      status: 'sending',
      message: null,
    });
    jsonp(subscribeUrl, { param: 'c' }, (err, data) => {
      console.log(err);
      console.log(data);
      console.log('data');
      if (err) {
        this.setState({
          status: 'error',
          message: err,
        });
      } else if (data.result === 'success') {
        this.setState({
          status: 'success',
          message: data.msg,
        });
      } else {
        this.setState({
          status: 'error',
          message: data.msg,
        });
      }
    });
  };

  handleSubmit = (e) => {
    this.subscribe(this.input.value);
    e.preventDefault();
  };

  render() {
    return (
      <div>
        {this.state.status === 'sending' && <div>Sending...</div>}
        {this.state.status === 'success' && (
          <div dangerouslySetInnerHTML={{ __html: this.state.message }} />
        )}
        {this.state.status === 'error' && (
          <div dangerouslySetInnerHTML={{ __html: this.state.message }} />
        )}
        <div className="input-group mb-3">
          <input
            ref={(input) => {
              this.input = input;
            }}
            className="form-control"
            type="email"
            placeholder="Your email"
          />
          <div className="input-group-append">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={this.handleSubmit}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    );
  }
}

// const MailingList = () => (
//   <MailchimpSubscribe
//     url={url}
//     render={({ subscribe, status, message }) => (
//       <div>
//         <SimpleForm onSubmitted={formData => subscribe(formData)} status={status} message={message} />
//       </div>
//     )}
//   />
// );

// const SimpleForm = ({ status, message, onSubmitted }) => {
//   let input;
//   const submit = () =>
//     input &&
//     input.value.indexOf('@') > -1 &&
//     onSubmitted({
//       EMAIL: input.value,
//     });

//   return (
//     <div>
//       {status}
//       {status === 'sending' &&
//         <div className="list-loading">Sending...</div>
//       }
//       {status === 'error' && (
//         <div
//           className="list-error"
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {status === 'success' && (
//         <span
//           className="list-success"
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       <div className="input-group mb-3">
//         <input
//           className="form-control"
//           ref={node => (input = node)}
//           type="email"
//           placeholder="Your email"
//         />
//         <div className="input-group-append">
//           <button className="btn btn-secondary" type="button" onClick={submit}>Sign Up</button>
//         </div>
//       </div>
//     </div>
//   );
// };

export default MailingList;
