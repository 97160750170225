import React from 'react';
import { SiteData } from 'react-static';
import { Link } from '@reach/router';

import './style.scss';

const Warning = (props) => (
  <SiteData>
    {({ warnings }) => (
      <div className={props.fixed ? 'fix-bottom' : 'filler'}>
        {warnings &&
          warnings.length > 0 &&
          warnings.map((warning) => (
            <Item warning={warning} key={warning.slug} />
          ))}
      </div>
    )}
  </SiteData>
);

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { warning } = this.props;
    if (this.state.isOpen) {
      return (
        <div className="warning">
          <button className="dismiss" onClick={this.toggle}>
            <i className="far fa-times" />
          </button>
          <div className="container">
            <Link to={`/updates/${warning.slug}`}>
              <div className="row align-items-center">
                <div className="col-md-auto">
                  <i className="fal fa-exclamation-triangle fa-2x pulse" />
                </div>
                <div className="col-md-auto">
                  <h2>{warning.title}</h2>
                  <p>{warning.excerpt}</p>
                </div>
                {/* <div className="col-md button-block">
                <Link
                  className="btn btn-outline-light"
                  to={`/updates/${warning.slug}`}
                >
                  More Info
                </Link>
              </div> */}
              </div>
            </Link>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Warning;
